import React, {useState} from "react"
import {graphql, navigate} from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import {ErrorMessage, Field, Form, Formik, getIn} from "formik"
import axios from "axios"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons"
import {faFacebook, faGithub, faLinkedin, faYoutube} from "@fortawesome/free-brands-svg-icons"

interface Props {
  readonly data: PageQueryData
}

const Contact: React.FC<Props> = ({data}) => {
  const {title, contact, social} = data.site.siteMetadata
  const [globalError, setGlobalError] = useState<string | null>(null)

  const getClassNames = (errors: any, fieldName: string) => {
    let classNames =
      "appearance-none block w-full bg-tercen-light-blue text-black border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
    if (getIn(errors, fieldName)) {
      classNames += " border-red-500"
    } else {
      classNames += " border-gray-200 focus:border-gray-500"
    }
    return classNames
  }

  return (
    <Layout title={title}>
      <Head title="Contact" keywords={[`data analysis`, `data analytics`, `data science`, `tercen`]} />
      <section className="full-w  py-16">
        <div className="container py-10 px-4">
          <h1 className="font-bold text-black text-6xl leading-tight">Get in contact</h1>
          <p className="text-2xl text-tercen-orange-base font-source-sans-pro">Our team is waiting for you</p>
        </div>
      </section>
      <section className="mx-4 mb-10">
        <div className="container flex flex-col lg:flex-row border border-tercen-light-blue">
          <div className="flex-1 lg:flex-auto bg-tercen-light-blue p-8">
            <h3 className="font-medium text-3xl text-black">{contact.title}</h3>
            <p className="pt-8 text-sm font-extralight">
              <FontAwesomeIcon icon={faPhone} className="text-base" />
              <a href={`tel:${contact.phone}`} className="pl-4">
                {contact.phone}
              </a>
            </p>
            <p className="py-1 md:py-3 text-sm font-extralight">
              <FontAwesomeIcon icon={faEnvelope} className="text-base" />
              <a href={`mailto:${contact.email}`} className="pl-4">
                {contact.email}
              </a>
            </p>
            <div className="pt-10">
              <a className="inline-block mr-4 text-3xl hover:text-tercen-blue" href={social.linkedin} target="_blank">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a className="inline-block mr-4 text-3xl hover:text-tercen-blue" href={social.github} target="_blank">
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a className="inline-block mr-4 text-3xl hover:text-tercen-blue" href={social.facebook} target="_blank">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a className="inline-block text-3xl hover:text-tercen-blue" href={social.youtube} target="_blank">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
          <div className="flex-1 lg:flex-auto">
            <Formik
              initialValues={{}}
              onSubmit={(values, actions) => {
                axios({
                  method: "POST",
                  url: "https://europe-west1-tercen-firebase.cloudfunctions.net/contactFunction",
                  data: values,
                })
                  .then((response) => {
                    actions.setSubmitting(false)
                    actions.resetForm()
                    navigate("/thank-you-form")
                  })
                  .catch((error) => {
                    actions.setSubmitting(false)
                    console.log(error)
                  })
              }}
              validate={(values) => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i

                const errors = {}
                setGlobalError(null)
                if (!values.name) {
                  errors.name = "Please fill your name in."
                }
                if (values.phone && !phoneRegex.test(values.phone)) {
                  errors.phone = "Please use a valid phone number format."
                }
                if (!values.email || !emailRegex.test(values.email)) {
                  errors.email = "Please fill a valid email address in."
                }
                if (!values.emailConfirmation || values.email != values.emailConfirmation) {
                  errors.emailConfirmation = "Email confirmation doesn't seem to match."
                }
                if (Object.keys(errors).length > 0) {
                  setGlobalError("Please review the errors in the form.")
                }
                return errors
              }}>
              {(formProps: any) => (
                <div className="container pt-10 px-5">
                  <Form className="w-full max-w-lg mx-auto text-sm">
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <h3 className="w-full text-xl text-center lg:text-left text-black">Send us a message</h3>
                    </div>
                    <div className="flex flex-wrap mb-6">
                      <div className="w-full px-1 mb-6 md:mb-0">
                        <label className="block uppercase font-medium text-xs mb-2" htmlFor="grid-first-name">
                          Name
                        </label>
                        <Field name="name" className={getClassNames(formProps.errors, "name")} />
                        <ErrorMessage name="name" component="p" className="text-red-500 text-xs italic" />
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                      <div className="w-full md:w-1/2 px-1 mb-6 md:mb-0">
                        <label className="block uppercase font-medium text-xs mb-2" htmlFor="grid-first-name">
                          Email
                        </label>
                        <Field name="email" className={getClassNames(formProps.errors, "email")} />
                        <ErrorMessage name="email" component="p" className="text-red-500 text-xs italic" />
                      </div>
                      <div className="w-full md:w-1/2 px-1">
                        <label className="block uppercase font-medium text-xs mb-2" htmlFor="grid-last-name">
                          Confirm Email
                        </label>
                        <Field
                          name="emailConfirmation"
                          className={getClassNames(formProps.errors, "emailConfirmation")}
                        />
                        <ErrorMessage name="emailConfirmation" component="p" className="text-red-500 text-xs italic" />
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                      <div className="w-1/2 px-1">
                        <div className="flex">
                          <label className="flex-grow uppercase font-medium text-xs mb-2" htmlFor="grid-first-name">
                            Phone
                          </label>
                          <span className="text-xs font-light text-right text-gray-500">Optional</span>
                        </div>
                        <Field name="phone" className={getClassNames(formProps.errors, "phone")} />
                        <ErrorMessage name="phone" component="p" className="text-red-500 text-xs italic" />
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                      <label className="px-1 block uppercase font-medium text-xs mb-2" htmlFor="grid-last-name">
                        Message
                      </label>
                      <div className="w-full px-1">
                        <Field name="message" as="textarea" className={getClassNames(formProps.errors, "message")} />
                      </div>
                    </div>
                    {globalError && (
                      <div className="flex flex-wrap mb-6">
                        <div className="w-full px-1">
                          <p className="text-red-500 text-xs italic">{globalError}</p>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-wrap mt-10 mb-6 justify-center">
                      <button
                        className="lg:mt-0 py-3 px-10 rounded-sm inline-block font-bold text-white bg-tercen-blue text-xs transition duration-500 ease-in-out hover:bg-gray-200 hover:text-black"
                        type="submit">
                        Send
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </Layout>
  )
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
      social: {
        linkedin: string
        github: string
        facebook: string
        youtube: string
      }
      contact: {
        title: string
        phone: string
        email: string
      }
    }
  }
}

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        social {
          linkedin
          facebook
          github
          youtube
        }
        contact {
          title
          email
          phone
        }
      }
    }
  }
`
export default Contact
